import React from "react"
import Layout from "../components/layout"
import { Container, Row, Col, Button, Accordion } from "react-bootstrap"
import useWindowSize from "../utils/useWindowSize"
import { navigate } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import { AiOutlineCaretRight } from "react-icons/ai"

export default function HealthcareWorkers() {
  const { width } = useWindowSize()
  return (
    <Layout>
      <Container
        fluid
        style={{
          backgroundColor: "white",
          color: "black",
          paddingTop: "4rem",
          minHeight: `${width < 768 ? "50vh" : "90vh"}`,
        }}
      >
        <Seo
          title="Discover Medication and CPD accredited CMEs for Providers"
          description="Access information on medication availability and cpd accredited continues medical education for healthcare professionals. Learn more..."
          keywords="Pharmaceutical companies in kenya,Medical suppliers in kenya,Medical suppliers in Nairobi,Pharmaceutical supply chain,List of pharmaceutical companies in kenya,Pharmaceutical wholesalers in Nairobi,Pharmaceutical distributors in kenya,Pharmaceutical distributors in Nairobi,companies looking for distributors in kenya,pharmaceutical importers in kenya,cme,cpd points,cpd points kenya,pharmacy and poisons board cpd points,cpd,ppb cpd points,online cpd points for doctor,kma webinars,psk webinars,ppb cpd portal"
          img="https://www.patameds.com/static/676b1bbdb4eb538818e42d43554b485c/5e32c/6.webp"
          siteUrl="https://www.patameds.com/healthcare_workers"
          canonical="https://www.patameds.com/healthcare_workers"
        ></Seo>
        <Row
          style={{
            paddingTop: `${width < 768 ? "10px" : "100px"}`,
            display: "flex",
            flexWrap: "wrap-reverse",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col xs={12} sm={4} style={{ margin: "auto" }}>
            <h1
              style={{
                color: "#03989e",
                fontWeight: "700",
                fontStyle: "normal",
              }}
            >
              Discover Medication and CMEs
            </h1>
            <p
              style={{
                fontWeight: "400",
                fontStyle: "normal",
                textAlign: "justify",
                width: "80%",
              }}
            >
              Access information on medication availability and cpd accredited
              continues medical education for healthcare professionals
            </p>

            <Button
              size="lg"
              style={{ backgroundColor: "#00A14B", marginBottom: "1rem" }}
              onClick={() => {
                navigate("/register")
              }}
            >
              Register <AiOutlineCaretRight style={{ display: "inline" }} />
            </Button>
          </Col>
          <Col xs={12} sm={6} style={{ padding: "10px" }}>
            <StaticImage
              src="../images/canva/6.png"
              layout="fullWidth"
              loading="eager"
              placeholder="tracedSVG"
              alt="Healthcare provider"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          backgroundColor: "#03989e",
          color: "white",
          minHeight: "100%",
        }}
      >
        <div
          style={{
            minHeight: "100v",
            paddingTop: `${width < 768 ? "0px" : "20px"}`,
          }}
        >
          <Row style={{ padding: "10px" }}>
            <h2 style={{ margin: "auto" }}>What you get</h2>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col xs={12} sm={4}>
              <div style={{ padding: "10px" }}>
                <StaticImage
                  src="../images/canva/7.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="madication availabilty"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div>
                <p style={{ textTransform: "uppercase" }}>
                  medication availability
                </p>
              </div>
              <div>
                <p>
                  You will be able to get information on new and existing drugs
                  available and registered in Kenya for different conditions
                </p>
              </div>
            </Col>

            <Col xs={12} sm={4}>
              <div style={{ padding: "10px" }}>
                <StaticImage
                  src="../images/canva/8.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="options"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div>
                <p style={{ textTransform: "uppercase" }}>options</p>
              </div>
              <div>
                <p>
                  Check brands/generics, where to find drugs, prices, search by
                  categories based on pharmacological class and anatomically
                </p>
              </div>
            </Col>

            <Col xs={12} sm={4}>
              <div style={{ padding: "10px" }}>
                <StaticImage
                  src="../images/canva/9.png"
                  layout="fullWidth"
                  loading="eager"
                  placeholder="tracedSVG"
                  alt="cpd points"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>
              <div>
                <p style={{ textTransform: "uppercase" }}>cpd points</p>
              </div>
              <div>
                <p>
                  You will get CPD points through curated Continuous Medical
                  Education, training, and webinars on the platform.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container
        fluid
        style={{
          minHeight: `${width < 758 ? "50vh" : "100vh"}`,
          backgroundColor: "#03989e",
          color: "black",
        }}
      >
        <Row
          style={{
            minHeight: "100v",
            paddingTop: `${width < 768 ? "10px" : "150px"}`,
          }}
        >
          <Col
            xs={12}
            sm={4}
            style={{ margin: "auto", backgroundColor: "#03989e" }}
          >
            <div style={{ color: "white" }}>
              <h2>Optimize your practice</h2>
            </div>
            <div style={{ color: "white" }}>
              <p>
                Access information on medication availability from a pool of
                130+ major distributors stocking 6300+ medications
              </p>
              <p>
                Enhance your knowledge with a curated list of cpd accredited
                medical education from various industry leaders
              </p>
            </div>
          </Col>
          <Col xs={12} sm={6} style={{ padding: "10px" }}>
            <StaticImage
              src="../images/canva/10.png"
              layout="fullWidth"
              loading="eager"
              placeholder="tracedSVG"
              alt="optimize your practice"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>

      <Container
        fluid
        style={{
          backgroundColor: "white",
          color: "black",
          minHeight: "100%",
        }}
      >
        <Row
          style={{
            minHeight: "100v",
            paddingTop: `${width < 768 ? "10px" : "150px"}`,
            display: "flex",
            flexWrap: "wrap-reverse",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col xs={12} sm={4} style={{ margin: "auto", padding: "10px" }}>
            <div>
              <h4>
                Join other healthcare workers embracing technology with Patameds
              </h4>
            </div>
            <div style={{ padding: "20px 0" }}>
              <Button
                onClick={() => {
                  navigate("/register")
                }}
                style={{ backgroundColor: "#00A14B" }}
              >
                Register <AiOutlineCaretRight style={{ display: "inline" }} />
              </Button>
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            style={{
              padding: "10px",
              minHeight: "100%",
            }}
          >
            <StaticImage
              src="../images/canva/11.png"
              layout="fullWidth"
              loading="eager"
              placeholder="tracedSVG"
              alt="register"
              style={{ width: "100%", height: "auto" }}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
